<template>
  <div v-if="!$vuetify.breakpoint.mdAndUp" class="text-center" style="margin-top: -20px;">
    <p style="font-family: Brush Script MT, Brush Script Std, cursive" :style="{
      fontSize: $vuetify.breakpoint.mdAndUp ? '4rem' : '3rem',
    }">
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data: () => ({})
}
</script>
